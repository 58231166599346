@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: "Inter", sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1aaf91;
}

/* Handle on Hover */
.css-1qeilas-MuiButtonBase-root-MuiRadio-root.Mui-checked{
  color: black;
}
.css-ebxnt3-MuiButtonBase-root-MuiTab-root.Mui-selected{
  color: black !important ;
}

.css-hcsn3-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
  color: rgba(0, 0, 0, 0.6) !important ;
}
.css-11pufo-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.css-15dwqo7-MuiInputBase-root-MuiOutlinedInput-root.MuiOutlinedInput-notchedOutline,
.css-15dwqo7-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border:1px solid rgba(0, 0, 0, 0.6) !important ;
}
.css-7m04wo-MuiButtonBase-root-MuiCheckbox-root.Mui-checked{
  color: black !important ;
}